<template>
  <div class="layout-logo" :style="style">
    <img src="/images/logo.png" style="width: 50px;height: 50px;" />
    <span v-if="siderCollapsed">企数通云财税</span>
    <span v-else>{{ webConfig.name || APP_NAME }}</span>
  </div>
</template>
<script>
import {mapState} from "vuex";
// const siderCollapsed = ref(false)

export default {
  name: "AppLogo",
  computed: {
    ...mapState(['webConfig']),
    style() {
      if (this.webConfig.homeLogo) {
        return {
          // backgroundImage: 'url("/api' + this.webConfig.homeLogo + '")'
        }
      }
      return {};
    }
  },
};
</script>
<style lang='less'>
.app-logo {
  line-height: @layout-header-height;
  height: @layout-header-height;
  overflow: hidden;
  white-space: nowrap;
  transition: padding .3s;
  text-align: center;
  background-color: #3C72FF;

  //background-image: url("../../assets/logo@3x.png");
  background-size: 85%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
}
.layout-logo {
  height: 150px;
  text-align: center;
  color: #000000;
  padding: 13.5px 0;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span{
    margin-top: 10px;
  }
}
.h-layout-sider-collapsed {
  .app-logo-text {
    display: none;
  }
}
</style>
